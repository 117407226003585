// Watch postal field
import docReady from '../../../assets/js/docReady';
import { registerPostalCheck } from '../../../assets/js/helpers/postalCheck';

function loadPostalCheck() {
    const postalChecks = Array.from(document.getElementsByClassName('postal-check-component'));

    postalChecks.forEach((postalCheck) => {
        registerPostalCheck(postalCheck);
    });
}

docReady(() => {
    loadPostalCheck();
});

window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
        loadPostalCheck();
    }
});
